.flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.hvr-spin-out {
    display: inline-block;
    vertical-align: middle;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    transition-duration: 1.3s;
    transition-property: transform;
}

.hvr-spin-in {
    display: inline-block;
    vertical-align: middle;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    transform: rotateY(3rad);
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    transition-duration: 1.3s;
    transition-property: transform;
}

.hvr-spin-out:hover,
.hvr-spin-out:focus,
.hvr-spin-out:active {
    transform: rotateY(3rad);
}

/* entire container, keeps perspective */
.flip-container {
    perspective: 1000px;
}
/* flip the pane when hovered */
.flip-container:hover .flipper, .flip-container.hover .flipper {
    transform: rotateY(180deg);
}

.flip-container, .front, .back {
    width: 250px;
    height: 250px;
}

/* flip speed goes here */
.flipper {
    transition: 0.6s;
    transform-style: preserve-3d;

    position: relative;
}

/* hide back of pane during swap */
.front, .back {
    backface-visibility: hidden;

    position: absolute;
    top: 0;
    left: 0;
}

/* front pane, placed above back */
.front {
    z-index: 2;
    /* for firefox 31 */
    transform: rotateY(0deg);
}

/* back, initially hidden pane */
.back {
    transform: rotateY(180deg);
}

.feature-box-design {
    border-radius: 4%;
    border-style: outset;
    border-width: 2px;
    border-color: #971D13;
    box-shadow: 4px 4px lightgrey;
}
