body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --ao-red: #971D13;
}

a, a:visited {
  color: #971D13;
  color: var(--ao-red);
}

p {
  margin:0
}

body {
  display:grid;
  justify-items: center;
  /* font-family: Arial, Helvetica, sans-serif; */
  font-family: alegreya;
}

#root {
  width:100%;
  background-color: #FFF;
}

#App {
  text-align: center;
  width: 100%;
}

.App-logo {
  max-height: 80px;
  height: 40vmin;
}

#header {
  background-color: #FAFAFA;
  border-bottom:1px solid #CBCBCB;
  padding-bottom:1em
}

nav {
  padding:5px;
  text-align: right;
  align-items: right; 
}

nav a {
  padding: 5px
}

#content {
  background-color: #FFF;
  padding-top:2em;
}

.iframe-holder {
  background: url(/static/media/spinner.af99faf2.gif) center 10% no-repeat;
}


@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.hvr-spin-out {
    display: inline-block;
    vertical-align: middle;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    transition-duration: 1.3s;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
}

.hvr-spin-in {
    display: inline-block;
    vertical-align: middle;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transform: rotateY(3rad);
            transform: rotateY(3rad);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    transition-duration: 1.3s;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
}

.hvr-spin-out:hover,
.hvr-spin-out:focus,
.hvr-spin-out:active {
    -webkit-transform: rotateY(3rad);
            transform: rotateY(3rad);
}

/* entire container, keeps perspective */
.flip-container {
    -webkit-perspective: 1000px;
            perspective: 1000px;
}
/* flip the pane when hovered */
.flip-container:hover .flipper, .flip-container.hover .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.flip-container, .front, .back {
    width: 250px;
    height: 250px;
}

/* flip speed goes here */
.flipper {
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;

    position: relative;
}

/* hide back of pane during swap */
.front, .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;

    position: absolute;
    top: 0;
    left: 0;
}

/* front pane, placed above back */
.front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
}

/* back, initially hidden pane */
.back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.feature-box-design {
    border-radius: 4%;
    border-style: outset;
    border-width: 2px;
    border-color: #971D13;
    box-shadow: 4px 4px lightgrey;
}

