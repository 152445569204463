:root {
  --ao-red: #971D13;
}

a, a:visited {
  color: var(--ao-red);
}

p {
  margin:0
}

body {
  display:grid;
  justify-items: center;
  /* font-family: Arial, Helvetica, sans-serif; */
  font-family: alegreya;
}

#root {
  width:100%;
  background-color: #FFF;
}

#App {
  text-align: center;
  width: 100%;
}

.App-logo {
  max-height: 80px;
  height: 40vmin;
}

#header {
  background-color: #FAFAFA;
  border-bottom:1px solid #CBCBCB;
  padding-bottom:1em
}

nav {
  padding:5px;
  text-align: right;
  align-items: right; 
}

nav a {
  padding: 5px
}

#content {
  background-color: #FFF;
  padding-top:2em;
}

.iframe-holder {
  background: url(assets/spinner.gif) center 10% no-repeat;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
